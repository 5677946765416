<template>
  <div class="text-gray-500">
    <span v-if="label">{{ $translate(label) }}</span>
    <vue-highcharts
      class="mt-8"
      type="chart"
      :options="options"
      :redrawOnUpdate="true"
      :oneToOneUpdate="false"
      :animateOnUpdate="true"
      ref="root"
    />
  </div>
</template>

<script>
import VueHighcharts from '@/use/highCharts'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueHighcharts,
  },
}
</script>
