import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)
  let roles = ref([])
  let organisations = ref([])
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()

  function listRecords() {
    loadHandler.setLoadingState('list_users', true)
    useApi.request('get', 'users', 'list').then((response) => {
      const data = response.data.data
      records.value = data
      loadHandler.setLoadingState('list_users', false)
    })
  }

  function openAdd() {
    addForm.create({
      type: 'edit',
      fields: userFields.value,
      data: {
        language: 'nl',
        role: roles.value[0].id,
      },
    })
    showAdd.value = true
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  function openEdit(payload) {
    const formPayload = {
      object_id: payload.object_id,
      email: payload.email,
      role: payload.role_v,
      language: payload.language_v,
      organisation: payload.organisation_v,
    }

    editForm.create({
      type: 'edit',
      fields: userFieldsEdit.value,
      data: formPayload,
    })
    showEdit.value = true
  }

  function closeEdit() {
    showEdit.value = false
  }

  function languageSelector(value) {
    const selector = {
      nl: 'Nederlands',
      en: 'English',
    }
    return selector[value]
  }

  function roleSelector(value) {
    const selector = roles.value.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})
    return selector[value].description
  }

  function organisationsSelector(value) {
    const selector = organisations.value.reduce((acc, item) => {
      acc[item.object_id] = item
      return acc
    }, {})
    return selector[value].name
  }

  function add() {
    loadHandler.setLoadingState('add_user', true)
    const payload = addForm.getData()

    const languageName = languageSelector(payload.language)
    const roleName = roleSelector(payload.role)
    const orgName = organisationsSelector(payload.organisation)

    payload.language_v = payload.language
    payload.organisation_v = payload.organisation
    payload.role_v = payload.role

    payload.language = languageName
    payload.organisation = orgName
    payload.role = roleName

    useApi.request('post', 'users', 'add', payload).then(() => {
      closeAdd()
      listRecords()
      loadHandler.setLoadingState('add_user', false)
    })
  }

  function edit() {
    loadHandler.setLoadingState('edit_user', true)
    const payload = editForm.getData()
    const languageName = languageSelector(payload.language)
    const roleName = roleSelector(payload.role)
    const orgName = organisationsSelector(payload.organisation)

    payload.language_v = payload.language
    payload.organisation_v = payload.organisation
    payload.role_v = payload.role

    payload.language = languageName
    payload.organisation = orgName
    payload.role = roleName

    useApi.request('post', 'users', 'update', payload).then(() => {
      closeEdit()
      listRecords()
      loadHandler.setLoadingState('edit_user', false)
    })
  }

  function deleteUser(objectId) {
    const payload = {
      object_id: objectId,
    }
    loadHandler.setLoadingState('delete_user', true)
    useApi.request('post', 'users', 'delete', payload).then(() => {
      listRecords()
      loadHandler.setLoadingState('delete_user', false)
    })
  }

  function listRoles(objectId) {
    const payload = {
      object_id: objectId,
    }
    loadHandler.setLoadingState('list_roles', true)
    useApi.request('get', 'auth', 'list_roles', payload).then((response) => {
      const result = response.data.data
      roles.value = result
      loadHandler.setLoadingState('list_roles', false)
    })
  }

  function listOrganisations(objectId) {
    const payload = {
      object_id: objectId,
    }
    loadHandler.setLoadingState('list_organisation', true)
    useApi.request('get', 'auth', 'list', payload).then((response) => {
      const result = response.data.data
      organisations.value = result
      loadHandler.setLoadingState('list_organisation', false)
    })
  }

  const selectRole = computed(() => {
    let payload = []
    roles.value.forEach((role) => {
      payload.push({
        label: role.name,
        value: role.id,
      })
    })
    return payload
  })

  const selectOrganisation = computed(() => {
    let payload = []
    organisations.value.forEach((organisation) => {
      payload.push({
        label: organisation.name,
        value: organisation.object_id,
      })
    })
    return payload
  })

  const userFields = computed(() => {
    return [
      {
        label: 'Email',
        key: 'email',
        type: 'email',
        searchable: true,
      },
      {
        label: 'Password',
        key: 'password',
        type: 'password',
        table: false,
      },
      {
        label: 'Language',
        key: 'language',
        type: 'select',
        options: selectValues.language,
        translate_value: true,
      },
      {
        label: 'Logins count',
        key: 'logins_count',
        type: 'string',
        edit: false,
      },
      {
        label: 'Creation date',
        key: 'created_at',
        type: 'date',
        edit: false,
        filter: 'dateFromEpochDay',
      },
      {
        label: 'Role',
        key: 'role',
        type: 'select',
        options: selectRole.value,
        searchable: true,
      },
      {
        label: 'Organisation',
        key: 'organisation',
        type: 'select',
        options: selectOrganisation.value,
        searchable: true,
      },
    ]
  })

  const userFieldsEdit = computed(() => {
    return [
      {
        label: 'Email',
        key: 'email',
        type: 'email',
        searchable: true,
      },
      {
        label: 'Language',
        key: 'language',
        type: 'select',
        options: selectValues.language,
        translate_value: true,
      },
      {
        label: 'Role',
        key: 'role',
        type: 'select',
        options: selectRole.value,
        searchable: true,
      },
      {
        label: 'Organisation',
        key: 'organisation',
        type: 'select',
        options: selectOrganisation.value,
        searchable: true,
      },
    ]
  })

  return {
    loadHandler,
    userFields,
    records,
    showEdit,
    showAdd,
    listRecords,
    addForm,
    editForm,
    openAdd,
    add,
    edit,
    deleteUser,
    listRoles,
    listOrganisations,
    closeAdd,
    roles,
    openEdit,
    closeEdit,
    userFieldsEdit,
  }
}
