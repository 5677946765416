<template>
  <div>
    <div class="relative w-full">
      <!-- button -->
      <base-button :variant="variant" :size="size" :loading="loading" :disabled="disabled" @action="handler.toggle()">
        <div class="flex flex-row">
          <span v-if="!icon" class="my-auto">
            <span v-if="translate">{{ $translate(displayText) }}</span>
            <span v-else>{{ displayText }}</span>
          </span>
          <span v-else><featherIcon class="w-4 text-gray-500" :icon="icon" /></span>
          <span v-if="!icon" class="mt-auto ml-2"><featherIcon class="w-4" icon="ChevronDownIcon" /></span>
        </div>
      </base-button>

      <!-- value list -->
      <div
        ref="dropdown_ref"
        v-if="handler.open.value"
        class="w-full rounded shadow-lg bg-white absolute mt-2 z-20 py-1"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import { ref, onMounted, onUnmounted } from 'vue'
export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    translate: {
      type: Boolean,
      default: true,
    },
    displayText: {
      type: String,
      default: 'normal',
    },
    icon: {
      type: String,
      default: '',
    },
    handler: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const dropdown_ref = ref(null)

    onMounted(() => window.addEventListener('click', clicker))
    onUnmounted(() => window.removeEventListener('click', clicker))

    function clicker(event) {
      if (props.handler.open.value) {
        if (!dropdown_ref.value.contains(event.target)) {
          props.handler.toggle()
        }
      }
    }

    return {
      dropdown_ref,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
