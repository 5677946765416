// import { computed } from 'vue'
import lookupHandler from '@/use/lookupHandler'
import filterHandler from '@/use/filterHandler'
import translationHandler from '@/plugins/i18n/translationHandler'

export default () => {
  const useLookup = lookupHandler()
  const useFilter = filterHandler()
  const useTranslate = translationHandler()

  function getOptions(type, data, title) {
    const selector = {
      Electricity: {
        chart: {
          type: 'column',
          marginTop: 60,
        },
        // navigation: {
        //   buttonOptions: {
        //     enabled: false,
        //   },
        // },
        colors: [useLookup.utilities.electricity.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // Color for the x-axis line
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // Color for the x-axis labels
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'kWh')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Color for the x-axis labels
            },
          },
          lineColor: 'rgb(156, 163, 175)', // Color for the x-axis line
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Sets the legend text color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'kWh')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
        exporting: {
          enabled: true,
          filename: 'data',
          buttons: {
            contextButton: {
              menuItems: ['downloadPNG', 'downloadCSV'],
              symbolStroke: 'rgb(156, 163, 175)',
            },
          },
        },
        lang: {
          downloadPNG: 'Download PNG',
          downloadCSV: 'Download CSV',
        },
      },
      Gas: {
        chart: {
          type: 'column',
        },
        colors: [useLookup.utilities.gas.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'm3')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'm3')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
        exporting: {
          enabled: true,
          filename: 'data',
          buttons: {
            contextButton: {
              menuItems: ['downloadPNG', 'downloadCSV'],
              symbolStroke: 'rgb(156, 163, 175)',
            },
          },
        },
        lang: {
          downloadPNG: 'Download PNG',
          downloadCSV: 'Download CSV',
        },
      },
      Water: {
        chart: {
          type: 'column',
        },
        colors: [useLookup.utilities.water.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'm3')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'm3')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
        exporting: {
          enabled: true,
          filename: 'data',
          buttons: {
            contextButton: {
              menuItems: ['downloadPNG', 'downloadCSV'],
              symbolStroke: 'rgb(156, 163, 175)',
              theme: {
                style: {
                  color: 'rgb(156, 163, 175)', // Changes the text color of the button
                },
              },
            },
          },
        },
        lang: {
          downloadPNG: 'Download PNG',
          downloadCSV: 'Download CSV',
        },
      },
      Heat: {
        chart: {
          type: 'column',
        },
        colors: [useLookup.utilities.heat.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'GJ')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'GJ')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
        exporting: {
          enabled: true,
          filename: 'data',
          buttons: {
            contextButton: {
              menuItems: ['downloadPNG', 'downloadCSV'],
              symbolStroke: 'rgb(156, 163, 175)',
              theme: {
                style: {
                  color: 'rgb(156, 163, 175)', // Changes the text color of the button
                },
              },
            },
          },
        },
        lang: {
          downloadPNG: 'Download PNG',
          downloadCSV: 'Download CSV',
        },
      },
      HeatCost: {
        chart: {
          type: 'column',
        },
        colors: [useLookup.utilities.heatcost.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${this.value}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${this.point.y} Index`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
        exporting: {
          enabled: true,
          filename: 'data',
          buttons: {
            contextButton: {
              menuItems: ['downloadPNG', 'downloadCSV'],
              symbolStroke: 'rgb(156, 163, 175)',
              theme: {
                style: {
                  color: 'rgb(156, 163, 175)', // Changes the text color of the button
                },
              },
            },
          },
        },
        lang: {
          downloadPNG: 'Download PNG',
          downloadCSV: 'Download CSV',
        },
      },
    }

    return selector[type]
  }

  return {
    getOptions,
  }
}
