import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()

  function listRecords() {
    loadHandler.setLoadingState('list_organisations', true)
    useApi.request('get', 'auth', 'list').then((response) => {
      const data = response.data.data
      records.value = data
      loadHandler.setLoadingState('list_organisations', false)
    })
  }

  function openAdd() {
    addForm.create({
      type: 'edit',
      fields: organisationFields.value,
      data: {},
    })
    showAdd.value = true
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  function openEdit(payload) {
    editForm.create({
      type: 'edit',
      fields: organisationFields.value,
      data: payload,
    })
    showEdit.value = true
  }

  function closeEdit() {
    showEdit.value = false
    editForm.close()
  }

  function add() {
    loadHandler.setLoadingState('add_organisation', true)

    const payload = addForm.getData()
    useApi.request('post', 'auth', 'add', payload).then(() => {
      closeAdd()
      listRecords()
      loadHandler.setLoadingState('add_organisation', false)
    })
  }

  function edit() {
    loadHandler.setLoadingState('edit_organisation', true)

    const payload = editForm.getData()
    useApi.request('post', 'auth', 'update', payload).then(() => {
      closeEdit()
      listRecords()
      loadHandler.setLoadingState('edit_organisation', false)
    })
  }

  function deleteOrganisation(objectId) {
    const payload = {
      object_id: objectId,
    }
    loadHandler.setLoadingState('delete_organisation', true)
    useApi.request('post', 'auth', 'delete', payload).then(() => {
      listRecords()
      loadHandler.setLoadingState('delete_organisation', false)
    })
  }

  const organisationFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Nodes',
      key: 'nodes_count',
      type: 'number',
      edit: false,
    },
  ])

  return {
    loadHandler,
    organisationFields,
    records,
    showEdit,
    showAdd,
    listRecords,
    addForm,
    editForm,
    openAdd,
    openEdit,
    add,
    edit,
    deleteOrganisation,
    closeAdd,
    closeEdit,
  }
}
