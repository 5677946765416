import { createRouter, createWebHistory } from 'vue-router'
import nodesView from '@/views/nodesView.vue'
import nodeDetailsView from '@/views/nodeDetailsView.vue'
import homeView from '@/views/homeView.vue'
import scenariosVieuw from '@/views/scenariosView.vue'
import scenariosVieuwAmounts from '@/views/scenariosViewAmounts.vue'
import costsView from '@/views/costs/costsView.vue'
import amountsView from '@/views/amounts/amountsView.vue'
import clustersView from '@/views/clustersView.vue'
import clusterDetailsView from '@/views/clusterDetailsView.vue'
import tagsView from '@/views/tagsView.vue'
import tagDetailsView from '@/views/tagDetailsView.vue'
import contractsView from '@/views/contractsView.vue'
import contractDetailsView from '@/views/contractDetailsView.vue'
import organisationsView from '@/views/organisationsView.vue'
import organisationDetailsView from '@/views/organisationDetailsView.vue'
import usersView from '@/views/usersView.vue'

import { authGuard } from '@auth0/auth0-vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: homeView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Home',
      titleIcon: 'HomeIcon',
    },
  },
  {
    path: '/nodes',
    name: 'nodes',
    component: nodesView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Nodes',
      titleIcon: 'CircleIcon',
    },
  },
  {
    path: '/nodes/:objectId',
    name: 'node_details',
    component: nodeDetailsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Node',
      titleIcon: 'CircleIcon',
    },
  },
  {
    path: '/costs',
    name: 'costs',
    component: scenariosVieuw,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Scenarios',
      titleIcon: 'DollarSignIcon',
    },
  },
  {
    path: '/costs_details/:objectId',
    name: 'costs_details',
    component: costsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Scenario',
      titleIcon: 'DollarSignIcon',
    },
  },
  {
    path: '/amounts',
    name: 'amounts',
    component: scenariosVieuwAmounts,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Amounts',
      titleIcon: 'BarChart2Icon',
    },
  },
  {
    path: '/amounts_details/:objectId',
    name: 'amounts_details',
    component: amountsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Scenario',
      titleIcon: 'BarChart2Icon',
    },
  },
  {
    path: '/clusters',
    name: 'clusters',
    component: clustersView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Clusters',
      titleIcon: 'Share2Icon',
    },
  },
  {
    path: '/clusters/:objectId',
    name: 'cluster_details',
    component: clusterDetailsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Cluster',
      titleIcon: 'Share2Icon',
    },
  },
  {
    path: '/tags',
    name: 'tags',
    component: tagsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Tags',
      titleIcon: 'TagIcon',
    },
  },
  {
    path: '/tags/:objectId',
    name: 'tag_details',
    component: tagDetailsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Tags',
      titleIcon: 'TagIcon',
    },
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: contractsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Contracts',
      titleIcon: 'FileTextIcon',
    },
  },
  {
    path: '/contracts/:objectId',
    name: 'contract_details',
    component: contractDetailsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Contract',
      titleIcon: 'FileTextIcon',
    },
  },
  {
    path: '/organisations',
    name: 'organisations',
    component: organisationsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Organisations',
      titleIcon: 'GlobeIcon',
    },
  },
  {
    path: '/organisations/:objectId',
    name: 'organisation_details',
    component: organisationDetailsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Organisation',
      titleIcon: 'GlobeIcon',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: usersView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Users',
      titleIcon: 'UserIcon',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
