<template>
  <div class="p-6">
    <base-page-title :title="meta.title" :icon="meta.titleIcon"></base-page-title>

    <baseTable
      :fields="useViewOrganisations.organisationFields.value"
      :records="useViewOrganisations.records.value"
      :records-per-page="25"
      :add-button="useContentGuard.canSee('organisation:write')"
      :edit-button="useContentGuard.canSee('organisation:write')"
      :delete-button="useContentGuard.canSee('organisation:write')"
      :clickAble="true"
      :clickCallback="tableAction"
      :loading="useViewOrganisations.loadHandler.somethingLoading.value"
      :hover-effect="true"
      leadIcon="GlobeIcon"
      @add="useViewOrganisations.openAdd()"
      @delete="useViewOrganisations.deleteOrganisation"
      @edit="useViewOrganisations.openEdit"
      :sortable="true"
    />

    <!-- add modal -->
    <base-modal
      v-if="useViewOrganisations.showAdd.value"
      @close="useViewOrganisations.closeAdd()"
      :title="`${$translate('Add')}`"
    >
      <baseForm :handler="useViewOrganisations.addForm" />
      <template v-slot:buttons>
        <baseButton
          @action="useViewOrganisations.add()"
          class="mr-2"
          :loading="useViewOrganisations.loadHandler.states.add_organisation"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>

    <!-- edit modal -->
    <base-modal
      v-if="useViewOrganisations.showEdit.value"
      @close="useViewOrganisations.closeEdit()"
      :title="`${$translate('Edit')}`"
    >
      <baseForm :handler="useViewOrganisations.editForm" />
      <template v-slot:buttons>
        <baseButton
          @action="useViewOrganisations.edit()"
          class="mr-2"
          :loading="useViewOrganisations.loadHandler.states.edit_organisation"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerOrganisations from '@/use/viewHandlerOrganisations'
import router from '@/router'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  setup() {
    const route = useRoute()
    const useViewOrganisations = viewHandlerOrganisations()
    const useContentGuard = contentGuardHandler()

    function tableAction(payload) {
      router.push({
        name: 'organisation_details',
        params: { objectId: payload.object_id },
      })
    }

    onMounted(() => {
      useViewOrganisations.listRecords()
    })

    const meta = computed(() => route.meta)
    return {
      meta,
      useViewOrganisations,
      tableAction,
      useContentGuard,
    }
  },
}
</script>
