<template>
  <div class="p-6">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <div class="flex flex-row">
      <span class="basis-1/2">
        <base-card class="text-gray-400" :loading="useViewNodeDetails.loadHandler.states.get_details">
          <!-- edit button -->
          <base-button
            v-if="useContentGuard.canSee('object:write')"
            class="mb-2"
            @action="useViewNodeDetails.openEdit()"
            ><featherIcon class="w-4 h-4" icon="EditIcon"
          /></base-button>

          <!-- list -->
          <base-list
            :data="useViewNodeDetails.node.value"
            :fields="useViewNodeDetails.nodeFields.value"
            :demo="useGlobal.settings.value.demo"
          />
        </base-card>

        <div class="flex -flex-row gap-4 mt-4">
          <!-- cluster card -->
          <base-card
            :loading="useViewNodeDetails.loadHandler.states.get_details"
            title="Cluster"
            titleIcon="Share2Icon"
            class="basis-1/2 hover:bg-gray-50 cursor-pointer"
            @click="clusterClick()"
          >
            <div v-if="useViewNodeDetails.node.value.cluster_id">{{ useViewNodeDetails.node.value.cluster_name }}</div>
            <div v-else>{{ $translate('Not assigned') }}</div>
          </base-card>

          <!-- contract card -->
          <base-card
            :loading="useViewNodeDetails.loadHandler.states.get_details"
            title="Contract"
            titleIcon="FileTextIcon"
            class="basis-1/2 hover:bg-gray-50 cursor-pointer"
            @click="contractClick()"
          >
            <div v-if="useViewNodeDetails.haveContracts.value">
              <p>
                {{ useViewNodeDetails.sortedContracts.value[0].provider }} -
                {{ useViewNodeDetails.sortedContracts.value[0].code }}
              </p>
              <p class="text-xs">
                {{ useFilter.filter(useViewNodeDetails.sortedContracts.value[0].start_date, 'dateFromEpochDay') }} -
                {{ useFilter.filter(useViewNodeDetails.sortedContracts.value[0].end_date, 'dateFromEpochDay') }}
              </p>
            </div>
            <div v-else>
              {{ $translate('Not assigned') }}
            </div>
          </base-card>
        </div>
      </span>
      <span class="basis-1/2 ml-4">
        <!-- map -->
        <base-card class="text-gray-400" :loading="useViewNodeDetails.loadHandler.states.get_details">
          <!-- edit button -->
          <nodeMap
            v-if="Object.keys(useViewNodeDetails.node.value).length > 0"
            :markerType="useViewNodeDetails.node.value.medium"
            :geometry="useViewNodeDetails.node.value.geometry"
            :nodeId="route.params.objectId"
            @new_geometry="updateNodeGeometry"
            @delete_geometry="updateNodeGeometry"
            :updateLoading="useViewNodeDetails.loadHandler.states.update_geometry"
          />
        </base-card>
      </span>
    </div>

    <!-- interval selector -->
    <intervalSelector class="mt-4" />

    <!-- usage -->
    <div class="flex flex-row mt-4">
      <base-card class="text-gray-400 w-full h-full">
        <div
          class="flex justify-center"
          v-if="showNoDataMessage && !useViewNodeDetails.loadHandler.somethingLoading.value"
        >
          <div>{{ $translate('No data') }}</div>
        </div>
        <div v-else class="flex justify-center">
          <baseLoader class="text-gray-500" :loading="useViewNodeDetails.loadHandler.somethingLoading.value" />
        </div>
        <cardChart
          v-if="
            !useViewNodeDetails.loadHandler.somethingLoading.value &&
            useViewNodeDetails.chart_data_usage.value.length > 0
          "
          :options="useViewNodeDetails.chart_options_usage.value"
          label="Usage"
        />
        <cardChart
          v-if="
            !useViewNodeDetails.loadHandler.somethingLoading.value &&
            useViewNodeDetails.chart_data_production_generation.value.length > 0
          "
          :options="useViewNodeDetails.chart_options_generation.value"
          label="Generation"
        />
        <cardChart
          v-if="
            !useViewNodeDetails.loadHandler.somethingLoading.value &&
            useViewNodeDetails.chart_data_production_return.value.length > 0
          "
          :options="useViewNodeDetails.chart_options_return.value"
          label="Return"
        />
      </base-card>
    </div>

    <!-- edit form -->
    <base-modal
      v-if="useViewNodeDetails.showEdit.value"
      @close="useViewNodeDetails.closeEdit()"
      :title="`${$translate('Edit')}`"
    >
      <baseForm :handler="useViewNodeDetails.editForm" />
      <template v-slot:buttons>
        <baseButton
          @action="useViewNodeDetails.edit()"
          class="mr-2"
          :loading="useViewNodeDetails.loadHandler.states.edit_node"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>

    <!-- contracts modal -->
    <base-modal
      v-if="useViewNodeDetails.showContracts.value"
      @close="useViewNodeDetails.closeContractsModal()"
      :title="`${$translate('Contracts')}`"
    >
      <baseTable
        :fields="useViewNodeDetails.contractFields.value"
        :records="useViewNodeDetails.sortedContracts.value"
        :records-per-page="25"
        :add-button="false"
        :edit-button="false"
        :delete-button="false"
        :clickAble="true"
        :clickCallback="goToContract"
        :hover-effect="true"
        leadIcon="FileTextIcon"
      />
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerNodeDetails from '@/use/viewHandlerNodeDetails'
import nodeMap from '@/views/components/nodeMap.vue'
import cardChart from '@/views/components/cardChart.vue'
import intervalSelector from '@/views/components/intervalSelector.vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import router from '@/router'
import filterHandler from '@/use/filterHandler'
import globalHandler from '@/use/handlerGlobal'

export default {
  setup() {
    const route = useRoute()
    const meta = computed(() => route.meta)
    const useViewNodeDetails = viewHandlerNodeDetails()
    const useContentGuard = contentGuardHandler()
    const useFilter = filterHandler()
    const useGlobal = globalHandler()

    onMounted(() => {
      useViewNodeDetails.getDetails({
        object_id: route.params.objectId,
      })
      useViewNodeDetails.getNodeTypes()
    })

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (!useViewNodeDetails.loadHandler.states.get_details) {
        return `${useViewNodeDetails.node.value.provider_identifier}`
      }
      return ''
    })

    function updateNodeGeometry(geometry) {
      useViewNodeDetails.node.value.geometry = geometry
      useViewNodeDetails.updateGeometry(geometry)
    }

    function clusterClick() {
      const clusterId = useViewNodeDetails.node.value.cluster_id
      if (clusterId) {
        router.push({
          name: 'cluster_details',
          params: { objectId: clusterId },
        })
      } else {
        router.push({
          name: 'clusters',
        })
      }
    }

    function contractClick() {
      const contracts = useViewNodeDetails.node.value.contracts

      if (contracts.length === 0) {
        router.push({
          name: 'contracts',
        })
      } else if (contracts.length === 1) {
        router.push({
          name: 'contract_details',
          params: { objectId: useViewNodeDetails.sortedContracts.value[0].object_id },
        })
      } else {
        useViewNodeDetails.openContractsModal()
      }
    }

    function goToContract(contract) {
      router.push({
        name: 'contract_details',
        params: { objectId: contract.object_id },
      })
    }

    const showNoDataMessage = computed(() => {
      const usage = useViewNodeDetails.chart_data_usage.value
      const prod_generation = useViewNodeDetails.chart_data_production_generation.value
      const prod_return = useViewNodeDetails.chart_data_production_return.value

      // Return true if all of the arrays are empty
      return usage.length === 0 && prod_generation.length === 0 && prod_return.length === 0
    })

    return {
      meta,
      pageTitle,
      useViewNodeDetails,
      pageSuffix,
      useContentGuard,
      route,
      updateNodeGeometry,
      clusterClick,
      contractClick,
      useFilter,
      goToContract,
      showNoDataMessage,
      useGlobal,
    }
  },
  components: {
    nodeMap,
    cardChart,
    intervalSelector,
    featherIcon,
  },
}
</script>
