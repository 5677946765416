import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const editForm = formHandler()
  const settingsForm = formHandler()
  const nodeTypesForm = formHandler()

  let organisation = ref({})
  let showEdit = ref(false)
  let showAddNodes = ref(false)
  let showAddNodetypes = ref(false)
  let assigned_nodes = ref([])
  let unassigned_nodes = ref([])
  let unassigned_nodes_selected = ref([])
  let node_types = ref([])

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request('post', 'auth', 'get_details', payload).then((response) => {
      const data = response.data.data
      organisation.value = data

      // create form
      settingsForm.create({
        type: 'edit',
        fields: settingsFields.value,
        data: data.settings,
      })

      loadHandler.setLoadingState('get_details', false)
    })
  }

  function getAssignedNodes(payload) {
    loadHandler.setLoadingState('get_assigned_nodes', true)
    useApi.request('post', 'nodes', 'get_assigned_admin', payload).then((response) => {
      assigned_nodes.value = response.data.data
      loadHandler.setLoadingState('get_assigned_nodes', false)
    })
  }

  function getUnAssignedNodes() {
    const payload = {
      organisation_id: organisation.value.object_id,
    }
    loadHandler.setLoadingState('get_unassigned_nodes', true)
    useApi.request('post', 'nodes', 'get_unassigned', payload).then((response) => {
      unassigned_nodes.value = response.data.data
      loadHandler.setLoadingState('get_unassigned_nodes', false)
    })
  }

  function getNodeTypes(payload) {
    loadHandler.setLoadingState('get_node_types', true)
    useApi.request('post', 'node_types', 'get_by', payload).then((response) => {
      node_types.value = response.data.data
      loadHandler.setLoadingState('get_node_types', false)
    })
  }

  function addNodeType() {
    loadHandler.setLoadingState('add_node_type', true)
    const payload = nodeTypesForm.getData()
    payload.organisation_id = organisation.value.object_id
    useApi.request('post', 'node_types', 'add', payload).then(() => {
      getNodeTypes({
        organisation_id: organisation.value.object_id,
      })
      closeAddNodeTypes()
      loadHandler.setLoadingState('add_node_type', false)
    })
  }

  function saveSettings() {
    loadHandler.setLoadingState('save_settings', true)
    const payload = {
      settings: settingsForm.getData(),
    }
    payload.object_id = organisation.value.object_id
    useApi.request('post', 'auth', 'update_organisation_settings', payload).then(() => {
      loadHandler.setLoadingState('save_settings', false)
    })
  }

  function deleteNodeType(objectId) {
    loadHandler.setLoadingState('delete_node_type', true)

    const payload = {
      object_id: objectId,
    }

    useApi.request('post', 'node_types', 'delete', payload).then(() => {
      getNodeTypes({
        organisation_id: organisation.value.object_id,
      })
      loadHandler.setLoadingState('delete_node_type', false)
    })
  }

  function openAddNodeTypes() {
    // create form
    nodeTypesForm.create({
      type: 'edit',
      fields: nodeTypeFields.value,
      data: {
        count_usage: 'Nee',
        count_generation: 'Nee',
        count_return: 'Nee',
      },
    })
    showAddNodetypes.value = true
  }

  function closeAddNodeTypes() {
    showAddNodetypes.value = false
  }

  function openAssign() {
    const getUnassignedPayload = {
      object_id: organisation.value.object_id,
    }

    getUnAssignedNodes(getUnassignedPayload)
    showAddNodes.value = true
  }

  function closeAssign() {
    unassigned_nodes.value = []
    showAddNodes.value = false
  }

  function setSelectedUnassignedNodes(selection) {
    unassigned_nodes_selected.value = selection.value
  }

  function unassignNode(payload) {
    const requestPayload = {
      object_id: payload,
    }

    loadHandler.setLoadingState('unassign_from_organisation', true)
    useApi.request('post', 'nodes', 'delete', requestPayload).then(() => {
      loadHandler.setLoadingState('unassign_from_organisation', false)

      const getDetailsPayload = {
        object_id: organisation.value.object_id,
      }

      getAssignedNodes(getDetailsPayload)
    })
  }

  const settingsFields = computed(() => {
    return [
      {
        label: 'Utilities',
        key: 'utilities',
        type: 'checkboxes',
        searchable: true,
        options: selectValues.medium,
      },
    ]
  })

  function assignNodes() {
    const payload = {
      id_list: Object.keys(unassigned_nodes_selected.value),
      organisation_id: organisation.value.object_id,
    }

    loadHandler.setLoadingState('assign_to_organisation', true)
    useApi.request('post', 'nodes', 'add', payload).then(() => {
      closeAssign()
      loadHandler.setLoadingState('assign_to_organisation', false)

      const getDetailsPayload = {
        object_id: organisation.value.object_id,
      }

      getAssignedNodes(getDetailsPayload)
    })
  }

  const nodeFields = ref([
    {
      label: 'Identification',
      key: 'provider_identifier',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
      searchable: true,
    },
    // {
    //   label: 'Name',
    //   key: 'p_client',
    //   type: 'string',
    //   searchable: true,
    // },
    {
      label: 'Address',
      key: 'address',
      type: 'string',
      table: true,
      searchable: true,
    },
    {
      label: 'Zip',
      key: 'zip',
      type: 'string',
      table: false,
      searchable: true,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Data',
      key: 'data_available',
      type: 'string',
    },
  ])

  const nodeTypeFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Count usage',
      key: 'count_usage',
      type: 'select',
      options: selectValues.jaNee,
    },
    {
      label: 'Count return',
      key: 'count_return',
      type: 'select',
      options: selectValues.jaNee,
    },
    {
      label: 'Count generation',
      key: 'count_generation',
      type: 'select',
      options: selectValues.jaNee,
    },
  ])

  return {
    loadHandler,
    showEdit,
    getDetails,
    editForm,
    showAddNodes,
    assigned_nodes,
    unassigned_nodes,
    unassigned_nodes_selected,
    organisation,
    settingsFields,
    settingsForm,
    getAssignedNodes,
    getUnAssignedNodes,
    nodeFields,
    openAssign,
    unassignNode,
    closeAssign,
    setSelectedUnassignedNodes,
    assignNodes,
    getNodeTypes,
    nodeTypeFields,
    node_types,
    nodeTypesForm,
    openAddNodeTypes,
    showAddNodetypes,
    closeAddNodeTypes,
    addNodeType,
    deleteNodeType,
    saveSettings,
  }
}
