import { ref } from 'vue'
// import timeHandler from '@/use/timeHandler'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
// import lookupHandler from './lookupHandler'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)
  const useApi = apiHandler()
  // const useLookup = lookupHandler()
  const loadHandler = loadingHandler()

  function listRecords() {
    loadHandler.setLoadingState('list_nodes', true)
    useApi.request('get', 'nodes', 'list').then((response) => {
      const data = response.data.data
      records.value = data
      loadHandler.setLoadingState('list_nodes', false)
    })
  }

  const nodeFields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
      demodata: 'Demoplaats',
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
      searchable: true,
      translate_value: true,
    },
    {
      label: 'Type',
      key: 'type',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Identification',
      key: 'provider_identifier',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
    {
      label: 'type_id',
      key: 'type_id',
      type: 'string',
      table: false,
    },
    // {
    //   label: 'p_client',
    //   key: 'p_client',
    //   type: 'string',
    //   table: false,
    // },
    {
      label: 'Residential',
      key: 'residential',
      type: 'string',
      table: false,
    },
    {
      label: 'Discount',
      key: 'discount',
      type: 'string',
      table: false,
    },
    {
      label: 'Size',
      key: 'size',
      type: 'string',
      table: false,
      translate_value: true,
      searchable: true,
    },
    {
      label: 'Street',
      key: 'street',
      type: 'string',
      searchable: true,
      table: false,
      demodata: 'Demostraat 10',
    },
    {
      label: 'zip',
      key: 'zip',
      type: 'string',
      table: false,
      searchable: true,
      demodata: '1234AB',
    },
    {
      label: 'data_available',
      key: 'data_available',
      type: 'string',
      table: false,
    },
    // {
    //   label: 'data_available_production',
    //   key: 'data_available_production',
    //   type: 'string',
    //   table: false,
    // },
    {
      label: 'geometry',
      key: 'geometry',
      type: 'string',
      table: false,
    },
    {
      label: 'unit',
      key: 'unit',
      type: 'string',
      table: false,
    },
    {
      label: 'contracted_power',
      key: 'contracted_power',
      type: 'string',
      table: false,
    },
  ])

  return {
    loadHandler,
    nodeFields,
    records,
    showEdit,
    showAdd,
    listRecords,
  }
}
