const size = [
  { label: 'Large', value: 'Large' },
  { label: 'Small', value: 'Small' },
]

const medium = [
  { label: 'Electricity', value: 'Electricity' },
  { label: 'Gas', value: 'Gas' },
  { label: 'Water', value: 'Water' },
  { label: 'Heat', value: 'Heat' },
  { label: 'HeatCost', value: 'HeatCost' },
]

const mediumContracts = [
  { label: 'Electricity', value: 'Electricity' },
  { label: 'Gas', value: 'Gas' },
  { label: 'Water', value: 'Water' },
  { label: 'Heat', value: 'Heat' },
]

const task_types = [
  { label: 'Sync nodes', value: 'Sync nodes' },
  { label: 'Reset nodes', value: 'Reset nodes' },
  { label: 'Delete nodes', value: 'Delete nodes' },
]

const months = [
  { label: 'Januari', value: 0 },
  { label: 'Februari', value: 1 },
  { label: 'Maart', value: 2 },
  { label: 'April', value: 3 },
  { label: 'Mei', value: 4 },
  { label: 'Juni', value: 5 },
  { label: 'Juli', value: 6 },
  { label: 'Augustus', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
]

const hours = [
  { label: '00:00', value: 0 },
  { label: '01:00', value: 1 },
  { label: '02:00', value: 2 },
  { label: '03:00', value: 3 },
  { label: '04:00', value: 4 },
  { label: '05:00', value: 5 },
  { label: '06:00', value: 6 },
  { label: '07:00', value: 7 },
  { label: '08:00', value: 8 },
  { label: '09:00', value: 9 },
  { label: '10:00', value: 10 },
  { label: '11:00', value: 11 },
  { label: '12:00', value: 12 },
  { label: '13:00', value: 13 },
  { label: '14:00', value: 14 },
  { label: '15:00', value: 15 },
  { label: '16:00', value: 16 },
  { label: '17:00', value: 17 },
  { label: '18:00', value: 18 },
  { label: '19:00', value: 19 },
  { label: '20:00', value: 20 },
  { label: '21:00', value: 21 },
  { label: '22:00', value: 22 },
  { label: '23:00', value: 23 },
]

const tagFilterLogic = [
  { label: 'Has at least all labels', value: 'subset' },
  { label: 'Has at least one of the labels', value: 'or' },
  { label: 'Has exactly all of the labels', value: 'and' },
]

const connector_types = [{ label: 'Ealyze', value: 'ealyze' }]

const jaNee = [
  { label: 'Ja', value: 'Ja' },
  { label: 'Nee', value: 'Nee' },
]

const interval = [
  { label: 'Year', value: 'year' },
  { label: 'Month', value: 'month' },
  { label: 'Day', value: 'day' },
]

const language = [
  { label: 'English', value: 'en' },
  { label: 'Dutch', value: 'nl' },
]

const offPeakHours = [
  { label: '23:00 - 07:00', value: '23:00 - 07:00' },
  { label: '21:00 - 07:00', value: '21:00 - 07:00' },
]

export default {
  size,
  medium,
  task_types,
  connector_types,
  months,
  tagFilterLogic,
  jaNee,
  interval,
  hours,
  language,
  offPeakHours,
  mediumContracts,
}
