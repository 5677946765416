import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const editForm = formHandler()

  let contract = ref({})
  let showAdd = ref(false)
  let showEdit = ref(false)
  let showAddNodes = ref(false)
  let unassigned_nodes = ref([])
  let unassigned_nodes_selected = ref([])

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request('post', 'contracts', 'get_details', payload).then((response) => {
      const data = response.data.data
      contract.value = data
      getUnassignedCluster()
      loadHandler.setLoadingState('get_details', false)
    })
  }

  const contractFields = ref([
    {
      label: 'Code',
      key: 'code',
      type: 'string',
    },
    {
      label: 'Type',
      key: 'type',
      type: 'string',
      component: 'pillMainType',
      disabled: true,
      translate_value: true,
    },
    {
      label: 'Provider',
      key: 'provider',
      type: 'string',
    },
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'Peak rate',
      key: 'electricity_peak_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Off-peak rate',
      key: 'electricity_offpeak_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Peak return rate',
      key: 'electricity_peak_return_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Off-peak return rate',
      key: 'electricity_offpeak_return_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Off-peak hours',
      key: 'off_peak_hours',
      type: 'select',
      options: selectValues.offPeakHours,
      table: false,
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Gas rate',
      key: 'gas_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Gas'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Water rate',
      key: 'water_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Water'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
  ])

  const nodeFields = ref([
    {
      label: 'Identification',
      key: 'provider_identifier',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
    },
    {
      label: 'Type',
      key: 'type',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Size',
      key: 'size',
      type: 'string',
      table: false,
    },
    {
      label: 'Zip',
      key: 'zip',
      type: 'string',
      table: false,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Residential',
      key: 'residential',
      type: 'string',
    },
    {
      label: 'Discount',
      key: 'discount',
      type: 'string',
    },
  ])

  function openEdit() {
    editForm.create({
      type: 'edit',
      fields: contractFields.value,
      data: contract.value,
    })
    showEdit.value = true
  }

  function closeEdit() {
    showEdit.value = false
  }

  function edit() {
    loadHandler.setLoadingState('edit_contract', true)
    const payload = editForm.getData()

    useApi.request('post', 'contracts', 'update', payload).then(() => {
      const getDetailsPayload = {
        object_id: payload.object_id,
      }

      getDetails(getDetailsPayload)
      closeEdit()
      loadHandler.setLoadingState('edit_contract', false)
    })
  }

  function openAddNodes() {
    showAddNodes.value = true
  }

  function closeAddNodes() {
    showAddNodes.value = false
  }

  function getUnassignedCluster() {
    const payload = {
      object_id: contract.value.object_id,
    }

    loadHandler.setLoadingState('get_unassigned_contract', true)
    useApi.request('post', 'nodes', 'get_unassigned_contract', payload).then((response) => {
      const data = response.data.data
      unassigned_nodes.value = data
      loadHandler.setLoadingState('get_unassigned_contract', false)
    })
  }

  function assignNodes() {
    const payload = {
      id_list: Object.keys(unassigned_nodes_selected.value),
      contract: contract.value.object_id,
    }

    loadHandler.setLoadingState('assign_to_cluster', true)
    useApi.request('post', 'nodes', 'assign_to_contract', payload).then(() => {
      closeAddNodes()
      loadHandler.setLoadingState('assign_to_cluster', false)

      const getDetailsPayload = {
        object_id: contract.value.object_id,
      }

      getDetails(getDetailsPayload)
    })
  }

  function setSelectedUnassignedNodes(selection) {
    unassigned_nodes_selected.value = selection.value
  }

  function unAssignNode(nodeId) {
    const payload = { node_id: nodeId, contract_id: contract.value.object_id }

    loadHandler.setLoadingState('unassign_node', true)
    useApi.request('post', 'nodes', 'unassign_from_contract', payload).then(() => {
      loadHandler.setLoadingState('unassign_node', false)
      const getDetailsPayload = {
        object_id: contract.value.object_id,
      }

      getDetails(getDetailsPayload)
    })
  }

  return {
    contract,
    loadHandler,
    contractFields,
    nodeFields,
    showEdit,
    showAdd,
    getDetails,
    editForm,
    openEdit,
    closeEdit,
    edit,
    openAddNodes,
    closeAddNodes,
    showAddNodes,
    getUnassignedCluster,
    assignNodes,
    setSelectedUnassignedNodes,
    unAssignNode,
    unassigned_nodes,
    unassigned_nodes_selected,
  }
}
