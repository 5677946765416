<template>
  <div class="p-6">
    <!-- title -->
    <base-page-title
      :title="meta.title"
      :suffix="useHome.organisationName.value"
      :icon="meta.titleIcon"
    ></base-page-title>

    <!-- interval button -->
    <intervalSelector />

    <!-- first row -->
    <div v-if="useHome.showInfo.value" class="grid grid-cols-6 gap-4 mt-4">
      <cardIndicator
        v-if="useHome.showElectricity.value"
        class="col-span-2"
        type="electricity"
        label="Usage"
        :value="useHome.amountElectricityUsage.value"
        :loading="useHome.loadHandler.states.get_summary_electricity"
      />

      <cardIndicator
        v-if="useHome.showElectricity.value"
        class="col-span-2"
        type="electricity"
        label="Generation"
        :value="useHome.amountElectricityGeneration.value"
        :loading="useHome.loadHandler.states.get_summary_electricity"
      />
      <cardIndicator
        v-if="useHome.showElectricity.value"
        class="col-span-2"
        type="electricity"
        label="Return"
        :value="useHome.amountElectricityReturn.value"
        :loading="useHome.loadHandler.states.get_summary_electricity"
      />

      <cardIndicator
        v-if="useHome.showGas.value"
        class="col-span-2"
        type="gas"
        label="Usage"
        :value="useHome.amountGasUsage.value"
        :loading="useHome.loadHandler.states.get_summary_gas"
      />
      <cardIndicator
        v-if="useHome.showWater.value"
        class="col-span-2"
        type="water"
        label="Usage"
        :value="useHome.amountWaterUsage.value"
        :loading="useHome.loadHandler.states.get_summary_water"
      />

      <cardIndicator
        v-if="useHome.showHeat.value"
        class="col-span-2"
        type="heat"
        label="Usage"
        :value="useHome.amountHeatUsage.value"
        :loading="useHome.loadHandler.states.get_summary_heat"
      />

      <!-- <cardIndicator class="col-span-2" type="melding" label="Messages" /> -->

      <base-card class="col-span-3 text-gray-400" v-if="useHome.showElectricity.value">
        <cardChart :options="useHome.chartOptionsElectricityUsage.value" label="Usage" />
      </base-card>

      <base-card class="col-span-3 text-gray-400" v-if="useHome.showElectricity.value">
        <cardChart :options="useHome.chartOptionsElectricityGeneration.value" label="Generation" />
      </base-card>

      <base-card class="col-span-3 text-gray-400" v-if="useHome.showElectricity.value">
        <cardChart :options="useHome.chartOptionsElectricityReturn.value" label="Return" />
      </base-card>

      <base-card class="col-span-3 text-gray-400" v-if="useHome.showGas.value">
        <cardChart :options="useHome.chartOptionsGasUsage.value" label="Usage" />
      </base-card>

      <base-card class="col-span-3 text-gray-400" v-if="useHome.showWater.value">
        <cardChart :options="useHome.chartOptionsWaterUsage.value" label="Usage" />
      </base-card>

      <base-card class="col-span-3 text-gray-400" v-if="useHome.showHeat.value">
        <cardChart :options="useHome.chartOptionsHeatUsage.value" label="Usage" />
      </base-card>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import intervalSelector from '@/views/components/intervalSelector.vue'
import viewHandlerHome from '@/use/viewhandlerHome'
import cardIndicator from '@/views/components/cardIndicator.vue'
import cardChart from '@/views/components/cardChart.vue'

export default {
  setup() {
    const route = useRoute()
    const meta = computed(() => route.meta)
    const useHome = viewHandlerHome()

    onMounted(() => {
      useHome.load()
    })

    return {
      meta,
      useHome,
    }
  },
  components: {
    intervalSelector,
    cardIndicator,
    cardChart,
  },
}
</script>
