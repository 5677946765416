<template>
  <!-- toastcontainer -->
  <div class="fixed top-0 right-0 text-white mt-2 mr-4">
    <div
      v-for="toast in toasts"
      :key="toast.id"
      :class="`${getBackgroundColor(toast.type)}`"
      class="px-4 py-2 m-2 rounded animate-fade w-96"
    >
      <div class="flex flex-row">
        <div class="mr-2 my-auto"><featherIcon class="w-4 h-4" :icon="getIcon(toast.type)" /></div>
        <div class="text-sm">{{ toast.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import toastHandler from '@/use/toastHandler'
import useTheme from '@/use/themeHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'

export default {
  setup() {
    const { toasts } = toastHandler()
    const reversedToasts = computed(() => [...toasts.value].reverse())
    const themeHandler = useTheme()

    function getBackgroundColor(variant) {
      return themeHandler.getBackgroundColor(variant)
    }

    function getIcon(variant) {
      const lookup = {
        gas: 'CheckIcon',
        danger: 'AlertTriangleIcon',
      }
      return lookup[variant]
    }

    return {
      toasts,
      getBackgroundColor,
      getIcon,
      reversedToasts,
    }
  },
  components: {
    featherIcon,
  },
}
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade {
  animation: fade 0.5s ease-in-out;
}
</style>
