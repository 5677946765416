<template>
  <div class="flex flex-row">
    <!-- icon -->
    <featherIcon class="w-4 h-4 my-auto mr-2 text-gray-500" icon="ClockIcon" />

    <!-- range selector -->
    <base-button-drop-down
      class="mr-2"
      :handler="rangeDropDown"
      :display-text="useTime.intervalDisplayText.value"
      variant="neutral"
    >
      <base-button-drop-down-item
        v-for="(displayText, key) in useTime.filteredDisplayTextSelector.value"
        :key="key"
        @click="toggleRange(key)"
      >
        {{ $translate(displayText) }}
      </base-button-drop-down-item>
    </base-button-drop-down>

    <!-- day filter -->
    <base-button-drop-down
      class="mr-2"
      v-if="useTime.range.value.includes(['day'])"
      :handler="dayDropDown"
      :display-text="String(useTime.selectedDay.value)"
      variant="neutral"
    >
      <base-button-drop-down-item v-for="day in useTime.days.value" :key="day.number" @click="toggleDay(day.number)">
        {{ day.number }}
      </base-button-drop-down-item>
    </base-button-drop-down>

    <!-- month filter -->
    <base-button-drop-down
      class="mr-2"
      v-if="useTime.range.value === 'day' || useTime.range.value === 'month'"
      :handler="monthDropDown"
      :display-text="monthNames[useTime.selectedMonth.value]"
      variant="neutral"
    >
      <base-button-drop-down-item
        v-for="month in useTime.months.value"
        :key="month.number"
        @click="toggleMonth(month.number - 1)"
      >
        {{ $translate(month.name) }}
      </base-button-drop-down-item>
    </base-button-drop-down>

    <!-- year filter -->
    <base-button-drop-down
      :handler="yearDropDown"
      :display-text="String(useTime.selectedYear.value)"
      variant="neutral"
      :translate="false"
    >
      <base-button-drop-down-item v-for="year in useTime.years.value" :key="year" @click="toggleYear(year)">
        {{ year }}
      </base-button-drop-down-item>
    </base-button-drop-down>
  </div>
</template>

<script>
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'
import timeHandler from '@/use/timeHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'

export default {
  setup() {
    const rangeDropDown = baseButtonDropDownHandler()
    const yearDropDown = baseButtonDropDownHandler()
    const monthDropDown = baseButtonDropDownHandler()
    const dayDropDown = baseButtonDropDownHandler()
    const useTime = timeHandler()

    function toggleRange(interval) {
      useTime.setInterval(interval)
      rangeDropDown.toggle()
    }

    function toggleYear(year) {
      useTime.updateSelectedYear(year)
      yearDropDown.toggle()
    }

    function toggleMonth(monthIndex) {
      useTime.updateSelectedMonth(monthIndex)
      monthDropDown.toggle()
    }

    function toggleDay(day) {
      useTime.updateSelectedDay(day)
      dayDropDown.toggle()
    }

    const field = {
      label: 'Label filtertype',
      key: 'filter_logic',
      type: 'select',
      options: [
        { label: 'Sync nodes', value: 'Sync nodes' },
        { label: 'Reset nodes', value: 'Reset nodes' },
        { label: 'Delete nodes', value: 'Delete nodes' },
      ],
      dependent_show_field_key: 'tags',
      dependent_show_field_type: 'labels',
      translate_value: true,
    }

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    return {
      toggleRange,
      toggleYear,
      toggleMonth,
      toggleDay,
      rangeDropDown,
      useTime,
      field,
      yearDropDown,
      monthDropDown,
      dayDropDown,
      monthNames,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
