import { useAuth0 } from '@auth0/auth0-vue'
import axios from 'axios'
import useToast from '@/use/toastHandler'

export default () => {
  const { getAccessTokenSilently, logout } = useAuth0()
  const toasthandler = useToast()
  const baseUrl = process.env.VUE_APP_API_BASEURL

  // request interceptor
  axios.interceptors.request.use(async (config) => {
    // use auth0 function to get token
    const accessToken = await getAccessTokenSilently()

    // add to header
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return config
  })

  // response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        console.error('Access token not found or unauthorized')
        // Logout and remove cookies
        logout({ logoutParams: { returnTo: window.location.origin } })
      } else if (error.response && error.response.status === 409) {
        toasthandler.addToast('Object heeft afhankelijkheden', 'danger')
      } else {
        toasthandler.addToast('Server error', 'danger')
      }

      return Promise.reject(error)
    },
  )

  async function request(type, object, endpoint, payload, headers) {
    if (type === 'post') {
      // gateway check if payload
      if (!payload) {
        console.error(`A Post request to ${endpoint} needs a payload!`)
        return null
      }

      // do the request
      const response = await axios.post(`${baseUrl}/${object}/${endpoint}`, payload, {
        headers: headers,
      })
      const responseData = await response
      return responseData
    } else {
      // do the request
      const response = await axios.get(`${baseUrl}/${object}/${endpoint}`)
      const responseData = await response
      return responseData
    }
  }

  return {
    request,
  }
}
