<template>
  <div class="p-6">
    <base-page-title :title="meta.title" :icon="meta.titleIcon"></base-page-title>

    <baseTable
      :fields="useViewUsers.userFields.value"
      :records="useViewUsers.records.value"
      :records-per-page="25"
      :add-button="useContentGuard.canSee('users:write')"
      :edit-button="useContentGuard.canSee('users:write')"
      :delete-button="useContentGuard.canSee('users:write')"
      :clickAble="false"
      :loading="useViewUsers.loadHandler.somethingLoading.value"
      :hover-effect="true"
      leadIcon="UserIcon"
      @add="useViewUsers.openAdd()"
      @delete="useViewUsers.deleteUser"
      @edit="useViewUsers.openEdit"
      :sortable="true"
    />

    <!-- add modal -->
    <base-modal v-if="useViewUsers.showAdd.value" @close="useViewUsers.closeAdd()" :title="`${$translate('Add')}`">
      <baseForm :handler="useViewUsers.addForm" />
      <template v-slot:buttons>
        <baseButton @action="useViewUsers.add()" class="mr-2" :loading="useViewUsers.loadHandler.states.add_user">{{
          $translate('Save')
        }}</baseButton>
      </template>
    </base-modal>

    <!-- edit modal -->
    <base-modal v-if="useViewUsers.showEdit.value" @close="useViewUsers.closeEdit()" :title="`${$translate('Edit')}`">
      <baseForm :handler="useViewUsers.editForm" />
      <template v-slot:buttons>
        <baseButton @action="useViewUsers.edit()" class="mr-2" :loading="useViewUsers.loadHandler.states.edit_user">{{
          $translate('Save')
        }}</baseButton>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerTags from '@/use/viewHandlerTags'
// import router from '@/router'
import contentGuardHandler from '@/use/contentGuardHandler'
import viewHandlerUsers from '@/use/viewHandlerUsers'

export default {
  setup() {
    const route = useRoute()
    const useViewUsers = viewHandlerUsers()
    const useViewTags = viewHandlerTags()
    const useContentGuard = contentGuardHandler()

    // function tableAction(payload) {
    //   router.push({
    //     name: 'cluster_details',
    //     params: { objectId: payload.object_id },
    //   })
    // }

    onMounted(() => {
      useViewUsers.listRecords()
      useViewUsers.listRoles()
      useViewUsers.listOrganisations()
    })

    const meta = computed(() => route.meta)
    return {
      meta,
      useViewTags,
      // tableAction,
      useContentGuard,
      useViewUsers,
    }
  },
}
</script>
