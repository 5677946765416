<template>
  <div class="relative">
    <div
      class="flex flex-wrap bg-white appearance-none border border-gray-200 rounded-l w-full p-2 px-4 gap-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm"
    >
      <base-pill
        class=""
        variant="secondary"
        close-button
        @close="deleteTag(index)"
        v-for="(tag, index) in tags"
        :key="index"
      >
        {{ tag.name }}
      </base-pill>
      <input
        class="w-fit focus:outline-none"
        type="text"
        :placeholder="$translate(placeHoldertext)"
        :value="tagName"
        @input="handleInput($event)"
        @keydown.delete="handleDelete($event)"
        @keydown.enter="addTag()"
        @blur="onBlur()"
      />
      <div v-if="fetchingLabels">
        <baseLoader :loading="fetchingLabels" />
      </div>
    </div>
    <!-- suggestions -->
    <div
      class="absolute bg-white rounded shadow-lg p-2 px-4 gap-2 border border-gray-100 text-sm w-full flex flex-wrap z-50 overflow-auto"
      v-if="showDropdown"
    >
      <base-pill
        clickable
        @mousedown.prevent
        @action="addTagSuggested(tag)"
        class=""
        variant="secondary"
        v-for="(tag, index) in suggested"
        :key="index"
      >
        {{ tag.name }}
      </base-pill>
      <!-- <div class="hover:bg-gray-50 p-2 cursor-pointer">piemel</div> -->
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'

export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'Add',
    },
  },
  emits: ['add', 'remove'],
  setup(props, { emit }) {
    let useApi = apiHandler()
    let tagName = ref('')
    let suggested = ref([])
    const fetchingLabels = ref(false)

    function handleInput(event) {
      const inputValue = event.target.value
      tagName.value = inputValue

      if (!inputValue) {
        suggested.value = []
      }

      debouncedGetSuggested(inputValue, 300)
    }

    const placeHoldertext = computed(() => {
      return props.mode === 'Search' ? 'Search tag' : 'Add tag'
    })

    const showDropdown = computed(() => {
      return suggested.value.length > 0 && tagName.value.length > 0 ? true : false
    })

    function handleDelete(event) {
      if (event.target.value === '' && event.key === 'Backspace') {
        deleteTag(props.tags.length - 1)
      }
    }

    function deleteTag(index) {
      emit('remove', index)
    }

    function addTag() {
      if (props.mode === 'Add') {
        if (tagName.value.trimEnd().trimStart()) {
          emit('add', { name: tagName.value })
          reset()
        }
      }
    }

    function addTagSuggested(tag) {
      reset()
      emit('add', { name: tag.name })
    }

    function debounce(func, delay) {
      let debounceTimer
      return function () {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
      }
    }

    const debouncedGetSuggested = debounce(getSuggested, 300)

    function getSuggested(input) {
      const payload = {
        query: input,
      }

      if (!fetchingLabels.value && tagName.value.length > 0) {
        fetchingLabels.value = true
        useApi.request('post', 'tags', 'get_suggested', payload).then((response) => {
          // Get tag names from props.tags
          const existingTagNames = props.tags.map((tag) => tag.name)

          // Filter out suggestions that are already in props.tags
          const uniqueSuggestions = response.data.data.filter(
            (suggestion) => !existingTagNames.includes(suggestion.name),
          )

          suggested.value = uniqueSuggestions
          fetchingLabels.value = false
        })
      }
    }

    function reset() {
      tagName.value = ''
      suggested.value = []
    }

    function onBlur() {
      // if (!showDropdown.value) {
      addTag()
      // }
    }

    return {
      handleInput,
      tagName,
      handleDelete,
      deleteTag,
      addTag,
      placeHoldertext,
      showDropdown,
      suggested,
      addTagSuggested,
      onBlur,
      fetchingLabels,
    }
  },
}
</script>
