import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  let costData = ref({})
  let taxData = ref({})
  let filter = ref({})
  let scenario = ref({})
  const message = ref('')
  const message_tax = ref('')
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const filterForm = formHandler()
  const showFilterModal = ref(false)
  const dateStartYear = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime()
  const dateEndYear = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0).getTime()

  const filterFields = ref([
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
    },
    {
      label: 'Tags',
      key: 'tags',
      type: 'tags',
      component: 'pillLabels',
      mode: 'Search',
    },
    {
      label: 'Label filtertype',
      key: 'filter_logic',
      type: 'select',
      options: selectValues.tagFilterLogic,
      dependent_show_field_key: 'tags',
      dependent_show_field_type: 'labels',
      translate_value: true,
    },
  ])

  function closeFilterModal() {
    filterForm.sync({
      start_date: dateStartYear,
      end_date: dateEndYear,
      filter_logic: 'subset',
      tags: [],
    })
    showFilterModal.value = false
  }

  function openFilterModal() {
    showFilterModal.value = true
  }

  function applyFilter() {
    updateScenario()
    summarizeCosts()
    summarizeTax()
    showFilterModal.value = false
  }

  const defaultFilter = {
    start_date: dateStartYear,
    end_date: dateEndYear,
    filter_logic: 'subset',
    tags: [],
  }

  filterForm.create({
    type: 'edit',
    fields: filterFields.value,
    data: defaultFilter,
  })

  function summarizeCosts() {
    loadHandler.setLoadingState('summarize_costs', true)

    const payload = {
      start_date: filterForm.data.value.start_date,
      end_date: filterForm.data.value.end_date,
      tags: filterForm.data.value.tags,
      filter_logic: filterForm.data.value.filter_logic,
    }

    useApi.request('post', 'nodes', 'calculate_costs', payload).then((response) => {
      const data = response.data.data
      if (data) {
        costData.value = data
      } else {
        costData.value = {}
        message.value = response.data.message
      }

      loadHandler.setLoadingState('summarize_costs', false)
    })
  }

  function summarizeTax() {
    loadHandler.setLoadingState('summarize_tax', true)

    const payload = {
      start_date: filterForm.data.value.start_date,
      end_date: filterForm.data.value.end_date,
      tags: filterForm.data.value.tags,
      filter_logic: filterForm.data.value.filter_logic,
    }

    useApi.request('post', 'tax_electricity', 'get_tax_costs', payload).then((response) => {
      const data = response.data.data

      if (data) {
        taxData.value = data
      } else {
        taxData.value = {}
        message_tax.value = response.data.message
      }

      loadHandler.setLoadingState('summarize_tax', false)
    })
  }

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request('post', 'scenarios', 'get_details', payload).then((response) => {
      const data = response.data.data
      scenario.value = data

      // settings
      const settings = data.configuration

      if (Object.keys(settings).length > 0) {
        filterForm.data.value.start_date = settings.start_date
        filterForm.data.value.end_date = settings.end_date
        filterForm.data.value.tags = settings.tags
        filterForm.data.value.filter_logic = settings.filter_logic
      } else {
        // do nothing
      }

      // load summaries
      summarizeCosts()
      summarizeTax()
      loadHandler.setLoadingState('get_details', false)
    })
  }

  function updateScenario() {
    const payload = {
      object_id: scenario.value.object_id,
      configuration: {
        start_date: filterForm.data.value.start_date,
        end_date: filterForm.data.value.end_date,
        tags: filterForm.data.value.tags,
        filter_logic: filterForm.data.value.filter_logic,
      },
    }
    loadHandler.setLoadingState('edit_scenario', false)
    useApi.request('post', 'scenarios', 'update', payload).then(() => {
      loadHandler.setLoadingState('edit_scenario', false)
    })
  }

  return {
    loadHandler,
    costData,
    taxData,
    filter,
    filterForm,
    showFilterModal,
    closeFilterModal,
    openFilterModal,
    applyFilter,
    summarizeCosts,
    summarizeTax,
    message,
    message_tax,
    scenario,
    getDetails,
    updateScenario,
  }
}
