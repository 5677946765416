import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import { useAuth0 } from '@auth0/auth0-vue'

export default () => {
  let records = ref([])
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const switchForm = formHandler()
  const showSwitchModal = ref(false)
  const auth = useAuth0()

  function listRecords() {
    loadHandler.setLoadingState('list_organisations', true)
    useApi.request('get', 'profile', 'list_organisations').then((response) => {
      const data = response.data.data
      records.value = data

      switchForm.create({
        type: 'edit',
        fields: switchFields.value,
        data: {},
      })

      loadHandler.setLoadingState('list_organisations', false)
    })
  }

  function organisationsSelector(value) {
    const selector = records.value.reduce((acc, item) => {
      acc[item.object_id] = item
      return acc
    }, {})
    return selector[value].name
  }

  function switchOrg() {
    loadHandler.setLoadingState('switch_organisations', true)

    const formData = switchForm.getData()
    const orgId = formData.organisation
    const orgName = organisationsSelector(orgId)
    const payload = { organisation_id: orgId, organisation_name: orgName }
    useApi.request('post', 'profile', 'switch_organisation', payload).then(() => {
      auth.logout({ returnTo: window.location.origin })
      loadHandler.setLoadingState('switch_organisations', false)
    })
  }

  function orgSelect() {
    let payload = []
    records.value.forEach((organisation) => {
      payload.push({
        label: organisation.name,
        value: organisation.object_id,
      })
    })
    return payload
  }

  const switchFields = computed(() => {
    return [
      {
        label: 'Organisation',
        key: 'organisation',
        type: 'select',
        options: orgSelect(),
      },
    ]
  })

  function openSwitcher() {
    listRecords()
    showSwitchModal.value = true
  }

  function closeSwitcher() {
    showSwitchModal.value = false
  }

  return {
    listRecords,
    switchForm,
    switchFields,
    openSwitcher,
    closeSwitcher,
    showSwitchModal,
    switchOrg,
    loadHandler,
  }
}
