import { ref, computed, watch } from 'vue'
import timeHandler from '@/use/timeHandler'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import chartOptionshandler from '@/use/chartOptionsHandler'

export default () => {
  const useTime = timeHandler()
  const range = useTime.range
  const interval = useTime.interval
  const useApi = apiHandler()
  const settings = ref({})
  const summaries = ref({})
  const loadHandler = loadingHandler()
  const useChartOptions = chartOptionshandler()
  let organisationName = ref('')

  const mainTypeSelector = {
    electricity: 'Electricity',
    gas: 'Gas',
    water: 'Water',
    heat: 'Heat',
  }

  function getSummary(medium) {
    const payload = {
      level: 'total',
      interval: interval.value,
      medium: mainTypeSelector[medium],
      filter: {
        total: [],
      },
      start_date: useTime.startDate.value.getTime(),
      end_date: useTime.endDate.value.getTime(),
    }

    const loadKey = `get_summary_${medium}`
    loadHandler.setLoadingState(loadKey, true)
    useApi.request('post', 'nodes', 'get_summary_hybrid', payload).then((response) => {
      const data = response.data.data.data
      summaries.value[medium] = data
      loadHandler.setLoadingState(loadKey, false)
    })
  }

  watch([() => range.value, () => useTime.startDate.value, () => useTime.endDate.value], async () => {
    reload()
  })

  function getSum(array) {
    let sum = 0
    array.forEach((innerArray) => {
      sum += innerArray[1]
    })
    return sum
  }

  const amountElectricityUsage = computed(() => {
    if (summaries.value.electricity) {
      return getSum(summaries.value.electricity.usage)
    }
    return 0
  })

  const seriesElectricityUsage = computed(() => {
    if (summaries.value.electricity) {
      return summaries.value.electricity.usage
    }
    return 0
  })

  const amountElectricityGeneration = computed(() => {
    if (summaries.value.electricity) {
      if (summaries.value.electricity.production) {
        return getSum(summaries.value.electricity.production.generation)
      }
      return 0
    }
    return 0
  })

  const seriesElectricityGeneration = computed(() => {
    if (summaries.value.electricity) {
      if (summaries.value.electricity.production) {
        return summaries.value.electricity.production.generation
      }
      return 0
    }
    return 0
  })

  const amountElectricityReturn = computed(() => {
    if (summaries.value.electricity) {
      if (summaries.value.electricity.production) {
        return getSum(summaries.value.electricity.production.return)
      }
      return 0
    }
    return 0
  })

  const seriesElectricityReturn = computed(() => {
    if (summaries.value.electricity) {
      if (summaries.value.electricity.production) {
        return summaries.value.electricity.production.return
      }
      return 0
    }
    return 0
  })

  const amountGasUsage = computed(() => {
    if (summaries.value.gas) {
      return getSum(summaries.value.gas.usage)
    }
    return 0
  })

  const seriesGasUsage = computed(() => {
    if (summaries.value.gas) {
      return summaries.value.gas.usage
    }
    return 0
  })

  const seriesHeatUsage = computed(() => {
    if (summaries.value.heat) {
      return summaries.value.heat.usage
    }
    return 0
  })

  const amountWaterUsage = computed(() => {
    if (summaries.value.water) {
      return getSum(summaries.value.water.usage)
    }
    return 0
  })

  const amountHeatUsage = computed(() => {
    if (summaries.value.heat) {
      return getSum(summaries.value.heat.usage)
    }
    return 0
  })

  const seriesWaterUsage = computed(() => {
    if (summaries.value.water) {
      return summaries.value.water.usage
    }
    return 0
  })

  function load() {
    // getting settings first
    loadHandler.setLoadingState('get_settings', true)
    useApi.request('get', 'auth', 'get_settings').then((response) => {
      const responseData = response.data.data
      settings.value = responseData.settings
      organisationName.value = responseData.name
      loadHandler.setLoadingState('get_settings', false)

      // then loading summaries
      getSummary('electricity')
      getSummary('gas')
      getSummary('water')
      getSummary('heat')
    })
  }

  function reload() {
    getSummary('electricity')
    getSummary('gas')
    getSummary('water')
    getSummary('heat')
  }

  const chartOptionsElectricityUsage = computed(() => {
    return useChartOptions.getOptions('Electricity', seriesElectricityUsage.value, 'Usage')
  })

  const chartOptionsElectricityGeneration = computed(() => {
    return useChartOptions.getOptions('Electricity', seriesElectricityGeneration.value, 'Generation')
  })

  const chartOptionsElectricityReturn = computed(() => {
    return useChartOptions.getOptions('Electricity', seriesElectricityReturn.value, 'Return')
  })

  const chartOptionsGasUsage = computed(() => {
    return useChartOptions.getOptions('Gas', seriesGasUsage.value, 'Usage')
  })

  const chartOptionsWaterUsage = computed(() => {
    return useChartOptions.getOptions('Water', seriesWaterUsage.value, 'Usage')
  })

  const chartOptionsHeatUsage = computed(() => {
    return useChartOptions.getOptions('Heat', seriesHeatUsage.value, 'Usage')
  })

  const showInfo = computed(() => {
    return Object.keys(settings.value).length > 0 ? true : false
  })

  const showElectricity = computed(() => {
    return settings.value.utilities.includes('Electricity') ? true : false
  })

  const showGas = computed(() => {
    return settings.value.utilities.includes('Gas') ? true : false
  })

  const showWater = computed(() => {
    return settings.value.utilities.includes('Water') ? true : false
  })

  const showHeat = computed(() => {
    return settings.value.utilities.includes('Heat') ? true : false
  })

  return {
    open,
    confirm,
    close,
    getSummary,
    load,
    settings,
    summaries,
    getSum,
    amountElectricityUsage,
    amountElectricityGeneration,
    amountElectricityReturn,
    amountGasUsage,
    amountWaterUsage,
    amountHeatUsage,
    interval,
    loadHandler,
    chartOptionsElectricityUsage,
    chartOptionsElectricityGeneration,
    chartOptionsElectricityReturn,
    chartOptionsGasUsage,
    chartOptionsWaterUsage,
    chartOptionsHeatUsage,
    organisationName,
    showInfo,
    showElectricity,
    showGas,
    showWater,
    showHeat,
  }
}
