import { ref, computed, watch } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import timeHandler from '@/use/timeHandler'
import chartOptionshandler from '@/use/chartOptionsHandler'
import formHandler from '@/use/formHandler'

export default () => {
  const useChartOptions = chartOptionshandler()
  const useTime = timeHandler()
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const interval = useTime.interval
  const range = useTime.range
  const editForm = formHandler()

  let cluster = ref({})
  let chart_data_usage = ref({})
  let chart_data_production_generation = ref({})
  let chart_data_production_return = ref({})
  let unassigned_nodes = ref([])
  let unassigned_nodes_selected = ref([])
  let showAdd = ref(false)
  let showAddNodes = ref(false)
  let showEdit = ref(false)

  const chart_options_usage = computed(() => {
    if (cluster.value) {
      return useChartOptions.getOptions(cluster.value.medium, chart_data_usage.value, 'Usage')
    }
    return useChartOptions.getOptions(cluster.value.medium, {})
  })

  const chart_options_generation = computed(() => {
    if (cluster.value) {
      return useChartOptions.getOptions(cluster.value.medium, chart_data_production_generation.value, 'Generation')
    }
    return useChartOptions.getOptions(cluster.value.medium, {})
  })

  const chart_options_return = computed(() => {
    if (cluster.value) {
      return useChartOptions.getOptions(cluster.value.medium, chart_data_production_return.value, 'Return')
    }
    return useChartOptions.getOptions(cluster.value.medium, {})
  })

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request('post', 'clusters', 'get_details', payload).then((response) => {
      const data = response.data.data
      cluster.value = data

      // check if cluster has nodes, else a summary is not needed
      if (data.nodes.length > 0) {
        const summaryPayload = {
          level: 'cluster',
          interval: interval.value,
          medium: data.medium,
          filter: {
            cluster: [data.object_id],
          },
          start_date: useTime.startDate.value.getTime(),
          end_date: useTime.endDate.value.getTime(),
        }

        getSummary(summaryPayload)
      }

      getUnassignedCluster()

      loadHandler.setLoadingState('get_details', false)
    })
  }

  function getSummary(payload) {
    loadHandler.setLoadingState('get_summary', true)
    useApi.request('post', 'nodes', 'get_summary_hybrid', payload).then((response) => {
      const data = response.data.data.data

      const { usage, production } = data
      const dataUsage = usage
      const dataGeneration = production.generation
      const dataReturn = production.return

      chart_data_usage.value = dataUsage
      chart_data_production_generation.value = dataGeneration
      chart_data_production_return.value = dataReturn

      loadHandler.setLoadingState('get_summary', false)
    })
  }

  function getUnassignedCluster() {
    const payload = {
      medium: cluster.value.medium,
      residential: cluster.value.residential,
      discount: cluster.value.discount,
    }

    loadHandler.setLoadingState('get_unassigned_cluster', true)
    useApi.request('post', 'nodes', 'get_unassigned_cluster', payload).then((response) => {
      const data = response.data.data
      unassigned_nodes.value = data
      loadHandler.setLoadingState('get_unassigned_cluster', false)
    })
  }

  const clusterFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'Creation date',
      key: 'date_created',
      type: 'string',
      filter: 'dateFromEpochDay',
      edit: false,
    },
    {
      label: 'Main type',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
      edit: false,
    },
    {
      label: 'Nodes',
      key: 'nodes_count',
      type: 'string',
      edit: false,
    },
    {
      label: 'Comment',
      key: 'comment',
      type: 'string',
      table: false,
    },
    {
      label: 'Tags',
      key: 'tags',
      type: 'tags',
      table: false,
      component: 'pillLabels',
    },
  ])

  const nodeFields = ref([
    {
      label: 'Identification',
      key: 'provider_identifier',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
    {
      label: 'Main type',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
      searchable: true,
    },
    {
      label: 'Type',
      key: 'type',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Size',
      key: 'size',
      type: 'string',
      table: false,
    },
    {
      label: 'Zip',
      key: 'zip',
      type: 'string',
      table: false,
      demodata: '1234AB',
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
      demodata: 'Demoplaats',
    },
    {
      label: 'Residential',
      key: 'residential',
      type: 'string',
    },
    {
      label: 'Discount',
      key: 'discount',
      type: 'string',
    },
  ])

  watch([() => range.value, () => useTime.startDate.value, () => useTime.endDate.value], async () => {
    const summaryPayload = {
      level: 'cluster',
      interval: interval.value,
      medium: cluster.value.medium,
      filter: {
        cluster: [cluster.value.object_id],
      },
      start_date: useTime.startDate.value.getTime(),
      end_date: useTime.endDate.value.getTime(),
    }

    getSummary(summaryPayload)
  })

  function openEdit() {
    editForm.create({
      type: 'edit',
      fields: clusterFields.value,
      data: cluster.value,
    })
    showEdit.value = true
  }

  function closeEdit() {
    showEdit.value = false
  }

  async function edit() {
    loadHandler.setLoadingState('edit_cluster', true)
    const payload = editForm.data.value

    if ('tags' in payload) {
      const tagPayload = {
        assign_id: payload.object_id,
        assign_to: 'cluster',
        tags: payload.tags.map((tag) => tag.name),
      }

      loadHandler.setLoadingState('add_tags', true)
      await useApi.request('post', 'tags', 'add_unique', tagPayload).then(() => {
        loadHandler.setLoadingState('add_tags', false)
        delete payload['tags']
      })
    }

    useApi.request('post', 'clusters', 'update', payload).then(() => {
      const getDetailsPayload = {
        object_id: payload.object_id,
      }

      getDetails(getDetailsPayload)
      closeEdit()
      loadHandler.setLoadingState('edit_cluster', false)
    })
  }

  function openAddNodes() {
    showAddNodes.value = true
  }

  function closeAddNodes() {
    showAddNodes.value = false
  }

  function assignNodes() {
    const payload = {
      id_list: Object.keys(unassigned_nodes_selected.value),
      cluster: cluster.value.object_id,
    }

    loadHandler.setLoadingState('assign_to_cluster', true)
    useApi.request('post', 'nodes', 'assign_to_cluster', payload).then(() => {
      closeAddNodes()
      loadHandler.setLoadingState('assign_to_cluster', false)

      const getDetailsPayload = {
        object_id: cluster.value.object_id,
      }

      getDetails(getDetailsPayload)
    })
  }

  function unAssignNode(nodeId) {
    const payload = {
      object_id: nodeId,
      cluster: null,
    }

    loadHandler.setLoadingState('unassign_node', true)
    useApi.request('post', 'nodes', 'update_node', payload).then(() => {
      loadHandler.setLoadingState('unassign_node', false)
      const getDetailsPayload = {
        object_id: cluster.value.object_id,
      }

      getDetails(getDetailsPayload)
    })
  }

  function setSelectedUnassignedNodes(selection) {
    unassigned_nodes_selected.value = selection.value
  }

  const mixedState = computed(() => {
    const mediums = []
    const discounts = []
    const residentials = []
    const node_keys = Object.keys(unassigned_nodes_selected.value)

    node_keys.forEach((node_key) => {
      const node = unassigned_nodes_selected.value[node_key]
      const { medium, discount, residential } = node
      mediums.push(medium)
      discounts.push(discount)

      if (medium === 'Electricity') {
        residentials.push(residential)
      }
    })

    const mainTypeCheck = mediums.every((val, i, arr) => val === arr[0])
    const discountCheck = discounts.every((val, i, arr) => val === arr[0])
    const residentialCheck = residentials.every((val, i, arr) => val === arr[0])
    const conclusionArray = [mainTypeCheck, discountCheck, residentialCheck]

    const conclusion = {
      issues: {
        unit: {
          status: mainTypeCheck,
          message: 'oneTypeCluster',
        },
        discount: {
          status: discountCheck,
          message: 'oneTypeDiscount',
        },
        residential: {
          status: residentialCheck,
          message: 'oneTypeResidential',
        },
      },
      conclusion: !conclusionArray.includes(false),
    }

    return conclusion
  })

  return {
    cluster,
    loadHandler,
    clusterFields,
    nodeFields,
    showEdit,
    showAdd,
    getDetails,
    chart_data_usage,
    chart_data_production_generation,
    chart_data_production_return,
    getSummary,
    chart_options_usage,
    chart_options_generation,
    chart_options_return,
    openEdit,
    closeEdit,
    editForm,
    edit,
    unassigned_nodes,
    unassigned_nodes_selected,
    showAddNodes,
    openAddNodes,
    closeAddNodes,
    assignNodes,
    setSelectedUnassignedNodes,
    unAssignNode,
    mixedState,
  }
}
