<template>
  <div class="p-6">
    <base-page-title :title="meta.title" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <!-- settings -->
    <base-page-title title="Settings" />
    <base-card :loading="useViewOrganisationDetails.loadHandler.states.get_details">
      <baseForm :handler="useViewOrganisationDetails.settingsForm" />
      <baseButton
        @action="useViewOrganisationDetails.saveSettings()"
        class="mr-2"
        :loading="useViewOrganisationDetails.loadHandler.states.save_settings"
        >{{ $translate('Save') }}</baseButton
      >
    </base-card>

    <!-- node types -->
    <base-page-title class="mt-4" title="Node types" />
    <baseTable
      :fields="useViewOrganisationDetails.nodeTypeFields.value"
      :records="useViewOrganisationDetails.node_types.value"
      :records-per-page="10"
      :add-button="useContentGuard.canSee('organisation:write')"
      :edit-button="false"
      :delete-button="useContentGuard.canSee('organisation:write')"
      :clickAble="false"
      :loading="useViewOrganisationDetails.loadHandler.somethingLoading.value"
      :hover-effect="true"
      leadIcon="EyeIcon"
      @add="useViewOrganisationDetails.openAddNodeTypes()"
      @delete="useViewOrganisationDetails.deleteNodeType"
      :sortable="true"
    />

    <!-- add node types modal -->
    <base-modal
      v-if="useViewOrganisationDetails.showAddNodetypes.value"
      @close="useViewOrganisationDetails.closeAddNodeTypes()"
      :title="`${$translate('Add')}`"
      size="l"
    >
      <baseForm :handler="useViewOrganisationDetails.nodeTypesForm" />
      <template v-slot:buttons>
        <baseButton
          @action="useViewOrganisationDetails.addNodeType()"
          class="mr-2"
          :loading="useViewOrganisationDetails.loadHandler.states.add_node_type"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>

    <!-- assigned nodes -->
    <base-page-title title="Nodes" />
    <baseTable
      :fields="useViewOrganisationDetails.nodeFields.value"
      :records="useViewOrganisationDetails.assigned_nodes.value"
      :records-per-page="10"
      :add-button="useContentGuard.canSee('organisation:write')"
      :edit-button="false"
      :delete-button="useContentGuard.canSee('organisation:write')"
      :clickAble="false"
      :loading="useViewOrganisationDetails.loadHandler.somethingLoading.value"
      :hover-effect="true"
      leadIcon="CircleIcon"
      @add="useViewOrganisationDetails.openAssign()"
      @delete="useViewOrganisationDetails.unassignNode"
      :sortable="true"
    />

    <!-- assiagn nodes modal -->
    <base-modal
      v-if="useViewOrganisationDetails.showAddNodes.value"
      @close="useViewOrganisationDetails.closeAssign()"
      :title="`${$translate('Add')}`"
      size="l"
    >
      <baseTable
        :fields="useViewOrganisationDetails.nodeFields.value"
        :records="useViewOrganisationDetails.unassigned_nodes.value"
        :records-per-page="10"
        :add-button="false"
        :edit-button="false"
        :delete-button="false"
        :selectable="true"
        :loading="useViewOrganisationDetails.loadHandler.states.get_unassigned_nodes"
        :hover-effect="true"
        leadIcon="CircleIcon"
        :search="true"
        @add="useViewOrganisationDetails.openAddNodes()"
        @selection="useViewOrganisationDetails.setSelectedUnassignedNodes"
        :sortable="true"
      />

      <template v-slot:buttons>
        <baseButton
          @action="useViewOrganisationDetails.assignNodes()"
          class="mr-2"
          :loading="useViewOrganisationDetails.loadHandler.states.assign_to_organisation"
          :disabled="useViewOrganisationDetails.unassigned_nodes_selected.value.length === 0"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerOrganisationDetails from '@/use/viewHandlerOrganisationDetails'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  setup() {
    const route = useRoute()
    const useViewOrganisationDetails = viewHandlerOrganisationDetails()
    const useContentGuard = contentGuardHandler()
    const meta = computed(() => route.meta)

    onMounted(() => {
      const payload = {
        object_id: route.params.objectId,
      }

      const payloadNodeTypes = {
        organisation_id: route.params.objectId,
      }

      useViewOrganisationDetails.getDetails(payload)
      useViewOrganisationDetails.getAssignedNodes(payload)
      useViewOrganisationDetails.getNodeTypes(payloadNodeTypes)
    })

    const pageSuffix = computed(() => {
      if (!useViewOrganisationDetails.loadHandler.states.get_details) {
        return `${useViewOrganisationDetails.organisation.value.name}`
      }
      return ''
    })

    return {
      useViewOrganisationDetails,
      useContentGuard,
      pageSuffix,
      meta,
    }
  },
}
</script>
