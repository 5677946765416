import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'
import chartOptionshandler from '@/use/chartOptionsHandler'

let amountsData = ref({})

export default () => {
  let filter = ref({})
  let scenario = ref({})
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const filterForm = formHandler()
  const showFilterModal = ref(false)
  const dateStartYear = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime()
  const dateEndYear = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0).getTime()
  const useChartOptions = chartOptionshandler()

  const filterFields = ref([
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
    },
    {
      label: 'Interval',
      key: 'interval',
      type: 'select',
      options: selectValues.interval,
      translate_value: true,
    },
    {
      label: 'Tags',
      key: 'tags',
      type: 'tags',
      component: 'pillLabels',
      mode: 'Search',
    },
    {
      label: 'Label filtertype',
      key: 'filter_logic',
      type: 'select',
      options: selectValues.tagFilterLogic,
      dependent_show_field_key: 'tags',
      dependent_show_field_type: 'labels',
      translate_value: true,
    },
  ])

  function closeFilterModal() {
    showFilterModal.value = false
  }

  function openFilterModal() {
    showFilterModal.value = true
  }

  function applyFilter() {
    updateScenario()
    getSummaryTimed('Electricity')
    getSummaryTimed('Gas')
    getSummaryTimed('Water')
    getSummaryTimed('Heat')
    showFilterModal.value = false
  }

  const defaultFilter = {
    start_date: dateStartYear,
    end_date: dateEndYear,
    filter_logic: 'subset',
    interval: 'year',
    tags: [],
  }

  filterForm.create({
    type: 'edit',
    fields: filterFields.value,
    data: defaultFilter,
  })

  function getSummaryTimed(type) {
    loadHandler.setLoadingState(`get_summary_timed_${type.toLowerCase()}`, true)

    const payload = {
      start_date: filterForm.data.value.start_date,
      end_date: filterForm.data.value.end_date,
      tags: filterForm.data.value.tags,
      filter_logic: filterForm.data.value.filter_logic,
      interval: filterForm.data.value.interval,
      medium: type,
    }

    useApi
      .request('post', 'nodes', 'get_summary_timed', payload)
      .then((response) => {
        const data = response.data.data.data
        amountsData.value[type] = data
        loadHandler.setLoadingState(`get_summary_timed_${type.toLowerCase()}`, false)
      })
      .catch(() => {
        loadHandler.setLoadingState(`get_summary_timed_${type.toLowerCase()}`, false)
      })
  }

  const dataElectricity = computed(() => {
    return amountsData.value['Electricity']
  })

  const dataGas = computed(() => {
    return amountsData.value['Gas']
  })

  const dataWater = computed(() => {
    return amountsData.value['Water']
  })

  const dataHeat = computed(() => {
    return amountsData.value['Heat']
  })

  const chart_options_usage_electricity = computed(() => {
    if (dataElectricity.value) {
      return useChartOptions.getOptions('Electricity', dataElectricity.value.usage, 'Usage')
    }
    return useChartOptions.getOptions('Electricity', {})
  })

  const chart_options_generation_electricity = computed(() => {
    if (dataElectricity.value) {
      return useChartOptions.getOptions('Electricity', dataElectricity.value.production.generation, 'Generation')
    }
    return useChartOptions.getOptions('Electricity', {})
  })

  const chart_options_return_electricity = computed(() => {
    if (dataElectricity.value) {
      return useChartOptions.getOptions('Electricity', dataElectricity.value.production.return, 'Return')
    }
    return useChartOptions.getOptions('Electricity', {})
  })

  const chart_options_usage_gas = computed(() => {
    if (dataGas.value) {
      return useChartOptions.getOptions('Gas', dataGas.value.usage, 'Usage')
    }
    return useChartOptions.getOptions('Gas', {})
  })
  const chart_options_usage_water = computed(() => {
    if (dataWater.value) {
      return useChartOptions.getOptions('Water', dataWater.value.usage, 'Usage')
    }
    return useChartOptions.getOptions('Water', {})
  })

  const chart_options_usage_heat = computed(() => {
    if (dataHeat.value) {
      return useChartOptions.getOptions('Heat', dataHeat.value.usage, 'Usage')
    }
    return useChartOptions.getOptions('Heat', {})
  })

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request('post', 'scenarios', 'get_details', payload).then((response) => {
      const data = response.data.data
      scenario.value = data

      // settings
      const settings = data.configuration

      if (Object.keys(settings).length > 0) {
        filterForm.data.value.start_date = settings.start_date
        filterForm.data.value.end_date = settings.end_date
        filterForm.data.value.tags = settings.tags
        filterForm.data.value.interval = settings.interval
        filterForm.data.value.filter_logic = settings.filter_logic
      } else {
        // do nothing
      }

      // load summaries
      getSummaryTimed('Electricity')
      getSummaryTimed('Gas')
      getSummaryTimed('Water')
      getSummaryTimed('Heat')
      loadHandler.setLoadingState('get_details', false)
    })
  }

  function updateScenario() {
    const payload = {
      object_id: scenario.value.object_id,
      configuration: {
        start_date: filterForm.data.value.start_date,
        end_date: filterForm.data.value.end_date,
        tags: filterForm.data.value.tags,
        interval: filterForm.data.value.interval,
        filter_logic: filterForm.data.value.filter_logic,
      },
    }
    loadHandler.setLoadingState('edit_scenario', false)
    useApi.request('post', 'scenarios', 'update', payload).then(() => {
      loadHandler.setLoadingState('edit_scenario', false)
    })
  }

  return {
    loadHandler,
    amountsData,
    filter,
    filterForm,
    showFilterModal,
    closeFilterModal,
    openFilterModal,
    applyFilter,
    getSummaryTimed,
    dataElectricity,
    dataGas,
    dataWater,
    dataHeat,
    chart_options_usage_electricity,
    chart_options_generation_electricity,
    chart_options_return_electricity,
    chart_options_usage_gas,
    chart_options_usage_water,
    chart_options_usage_heat,
    getDetails,
    updateScenario,
    scenario,
  }
}
