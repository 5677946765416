import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'

const settings = ref({})

export default () => {
  const loadHandler = loadingHandler()
  const useApi = apiHandler()

  function getSettings() {
    // getting settings first
    loadHandler.setLoadingState('get_settings', true)
    useApi.request('get', 'auth', 'get_settings').then((response) => {
      const responseData = response.data.data
      settings.value = responseData.settings
      loadHandler.setLoadingState('get_settings', false)
    })
  }

  return {
    getSettings,
    settings,
  }
}
