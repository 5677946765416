<template>
  <!-- split sidebar and rest -->
  <div class="flex flex-col w-full h-full p-4">
    <!-- logo -->
    <div class="w-full">
      <logoSideBar class="w-full h-full" />
    </div>

    <!-- content -->
    <div class="mt-8">
      <ul>
        <li v-for="item in items" :key="item">
          <span v-if="useContentGuard.canSee(item.permission)">
            <div
              v-if="!item.header"
              @click="goTo(item.route_name)"
              class="flex flex-row font-montserrat text-gray-500 p-2 hover:translate-x-1 ease-in-out duration-300 cursor-pointer"
            >
              <div class="mr-4 my-auto"><featherIcon class="w-5 h-5" :icon="item.icon" /></div>
              <div class="align-middle">{{ $translate(item.label) }}</div>
            </div>
            <div v-else class="flex flex-row text-xs font-montserrat text-gray-500 p-2 mt-4 uppercase">
              {{ $translate(item.header) }}
            </div>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import useTheme from '@/use/themeHandler'
import logoSideBar from '@/components/svg/logoSideBar.vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import { useRouter } from 'vue-router'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  setup() {
    const themeHandler = useTheme()
    const surfaceColour = themeHandler.getBackgroundColor('surface')
    const backgroundColor = themeHandler.getBackgroundColor('background')
    const useContentGuard = contentGuardHandler()

    const router = useRouter()

    const items = [
      { label: 'Home', icon: 'HomeIcon', route: '/', permission: 'page:view', route_name: 'home' },
      { header: 'Design', permission: 'page:view' },
      { label: 'Nodes', icon: 'CircleIcon', route: '/nodes', permission: 'page:view', route_name: 'nodes' },
      { label: 'Clusters', icon: 'Share2Icon', route: '/clusters', permission: 'page:view', route_name: 'clusters' },
      {
        label: 'Contracts',
        icon: 'FileTextIcon',
        route: 'contracts',
        permission: 'page:view',
        route_name: 'contracts',
      },
      { label: 'Tags', icon: 'TagIcon', route: 'tags', permission: 'page:view', route_name: 'tags' },
      { header: 'Reporting', permission: 'page:view' },
      { label: 'Costs', icon: 'DollarSignIcon', route: 'costs', permission: 'page:view', route_name: 'costs' },
      { label: 'Amounts', icon: 'BarChart2Icon', route: 'amounts', permission: 'page:view', route_name: 'amounts' },
      { header: 'Admin', permission: 'page:admin' },
      { label: 'Users', icon: 'UserIcon', route: 'users', permission: 'page:admin', route_name: 'users' },
      {
        label: 'Organisations',
        icon: 'GlobeIcon',
        route: 'organisations',
        permission: 'page:admin',
        route_name: 'organisations',
      },
    ]

    function goTo(route_name) {
      router.push({ name: route_name })
    }

    return {
      backgroundColor,
      surfaceColour,
      items,
      goTo,
      useContentGuard,
    }
  },
  components: {
    logoSideBar,
    featherIcon,
  },
}
</script>
